import React from "react"
import styled from "styled-components"

function Privacy() {
  return (
    <Container>
      <Wrapper>
        <Title>Terms of Service</Title>
        {/* <SubTitle>
          Welcome to Writers Way Solutions LLC! We are delighted to offer you
          the option to receive SMS notifications regarding updates, promotions,
          alerts, and other relevant information about our services. By
          providing your mobile phone number and opting in to receive SMS
          notifications, you agree to the following terms and conditions:
        </SubTitle> */}
      </Wrapper>
      <Wrapper>
        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            1.
          </span>
          <PrivacyText>
            <strong>Message Content and Frequency:</strong> Writers Way
            Solutions may contact clients via SMS to address questions and
            provide updates on project progress. Message frequency may vary.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            2.
          </span>
          <PrivacyText>
            <strong>Opt-Out Process:</strong> You can cancel the SMS service at
            any time. To unsubscribe, simply text “STOP.” You will receive a
            confirmation SMS to verify your unsubscription, and you will no
            longer receive messages from us. If you wish to rejoin the SMS
            service, please email us at customersupport@writerswaysolutions.com.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            3.
          </span>
          <div
            css={`
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              gap: 0.2rem;
            `}
          >
            <PrivacyText>
              <strong>Support:</strong> For any issues with the messaging
              program, you can:
            </PrivacyText>
            <ul>
              <li
                css={`
                  display: flex;
                  align-items: flex-start;
                  gap: 0.2rem;
                `}
              >
                <span
                  css={`
                    font-size: 16px;
                    line-height: 1.5;
                    color: #111;
                    font-weight: 700;
                  `}
                >
                  •
                </span>
                <PrivacyText>
                  {" "}
                  Email us at customersupport@writerswaysolutions.com and text
                  “HELP” for assistance, or
                </PrivacyText>
              </li>{" "}
              <li
                css={`
                  display: flex;
                  align-items: flex-start;
                  gap: 0.2rem;
                `}
              >
                <span
                  css={`
                    font-size: 16px;
                    line-height: 1.5;
                    color: #111;
                    font-weight: 700;
                  `}
                >
                  •
                </span>
                <PrivacyText>Call us at 1-888-666-4258. </PrivacyText>
              </li>
            </ul>
          </div>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            4.
          </span>
          <PrivacyText>
            <strong>Carrier Responsibility:</strong> Carriers are not
            responsible for undelivered or delayed messages.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            5.
          </span>
          <PrivacyText>
            <strong>Fees:</strong> Standard messaging and data rates may apply
            for messages sent between us. For questions about your text or data
            plan, please contact your wireless provider.
          </PrivacyText>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            gap: 0.2rem;
          `}
        >
          <span
            css={`
              font-size: 16px;
              line-height: 1.5;
              color: #111;
              font-weight: 700;
            `}
          >
            6.
          </span>
          <PrivacyText>
            <strong>Privacy:</strong> For information regarding our privacy
            practices, please refer to our Privacy Policy.
          </PrivacyText>
        </div>
      </Wrapper>
    </Container>
  )
}

export default Privacy

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem calc((100vw - 1200px) / 2);
  padding-top: 2rem;
  @media screen and (max-width: 768px) {
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.main1};
`
const SubTitle = styled.h5`
  font-family: ${props => props.theme.fonts.secondary};
  color: ${props => props.theme.colors.main1};
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-align: justify;
`
const PrivacyText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #111;
  font-weight: 400;
  text-align: justify;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const TextInfo = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: ${props => props.theme.colors.dark2};
`
